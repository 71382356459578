export enum UserRoleName {
  USER = 'user',
  COMPANY_MEMBER = 'companyMember',
  WALLET_USER = 'walletUser',
  WALLET_ADMIN = 'walletAdmin',
  ULTRA_ADMIN = 'ultraAdmin',
  MARKET_USER = 'marketUser',
  MARKET_ADMIN = 'marketAdmin',
  GAME_STORE_USER = 'gameStoreUser',
  GDC_ADMIN = 'gdcAdmin',
}

export interface IUserRoles {
  [UserRoleName.USER]: boolean;
  [UserRoleName.ULTRA_ADMIN]: boolean;
  [UserRoleName.COMPANY_MEMBER]: boolean;
  [UserRoleName.WALLET_USER]: boolean;
  [UserRoleName.WALLET_ADMIN]: boolean;
  [UserRoleName.MARKET_USER]: boolean;
  [UserRoleName.MARKET_ADMIN]: boolean;
  [UserRoleName.GAME_STORE_USER]: boolean;
  [UserRoleName.GDC_ADMIN]: boolean;
}
